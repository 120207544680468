import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import BooleanField from 'modules/shared/fields/booleanField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import FilesField from 'modules/shared/fields/filesField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';

function label(name) {
  return i18n(`entities.order.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.order.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  trackingCode: new StringField(
    'trackingCode',
    label('trackingCode'),
    {
      max: 46,
      min: 4
    },
  ),
  referenceNumber: new StringField(
    'referenceNumber',
    label('referenceNumber'),
    {
      max: 46,
      min: 4
    },
  ),
  shipper: new RelationToOneField(
    'shipper',
    label('shipper'),
    {
      required: true,
    },
  ),
  company: new RelationToOneField(
    'company',
    label('company'),
    {
      required: true,
    },
  ),
  qrcodes: new RelationToManyField(
    'qrcodes',
    label('qrcodes'),
    {},
  ),
  carrier: new RelationToManyField(
    'carrier',
    label('carrier'),
    {},
  ),
  products: new RelationToOneField(
    'products',
    label('products'),
    {},
  ),
  ebox: new RelationToOneField('ebox', label('ebox'), {
    required: false,
  }),
  invoice: new RelationToOneField('invoice', label('invoice'), {
    required: false,
  }),
  invoiceNumber: new StringField(
    'invoiceNumber',
    label('invoiceNumber'),
    {
      required: false,
      min: 6,
      max: 8,
    },
  ),
  cluster: new RelationToOneField('cluster', label('cluster'), {}),
  compartimentNumber: new StringField(
    'compartimentNumber',
    label('compartimentNumber'), {}
  ),
  compartimentNumberSize: new RelationToOneField(
    'compartiment',
    label('compartiment'),
    {}
  ),
  toCustomer: new RelationToOneField(
    'toCustomer',
    label('toCustomer'),
    {
      required: false,
    },
  ),
  toCustomerLevel1: new RelationToOneField(
    'toCustomer',
    label('toCustomer'),
    {
      required: true,
    },
  ),
  toCustomerLevel2: new RelationToOneField(
    'toCustomer',
    label('toCustomer'),
    {
      required: true,
    },
  ),
  accessCode: new StringField(
    'accessCode',
    label('accessCode'),
    {
      min: 6,
      max: 6,
    },
  ),
  attachments: new FilesField(
    'attachments',
    label('attachments'),
    'order/attachments',
    {
      size: 1000000,
      formats: ['txt', 'pdf'],
      max: 3,
    },
  ),
  delivered: new BooleanField(
    'delivered',
    label('delivered'),
  ),
  fromCustomer: new RelationToOneField(
    'fromCustomer',
    label('fromCustomer'),
    {},
  ),
  inboundAt: new DateTimeField(
    'inboundAt',
    label('inboundAt'),
    {},
  ),
  inboundBy: new RelationToOneField(
    'inboundBy',
    label('inboundBy'),
    {},
  ),
  storedAt: new DateTimeField(
    'storedAt',
    label('storedAt'),
    {},
  ),
  storedBy: new RelationToOneField(
    'storedBy',
    label('storedBy'),
    {},
  ),
  collectedAt: new DateTimeField(
    'collectedAt',
    label('collectedAt'),
    {},
  ),
  collectedBy: new RelationToOneField(
    'collectedBy',
    label('collectedBy'),
    {},
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  expiredInboundAt: new DateTimeField(
    'expiredInboundAt',
    label('expiredInboundAt'),
  ),
  expiredInboundBy: new RelationToOneField(
    'expiredInboundBy',
    label('expiredInboundBy'),
  ),
  expiredReturnedAt: new DateTimeField(
    'expiredReturnedAt',
    label('expiredReturnedAt'),
  ),
  courierReturnedAt: new DateTimeField(
    'courierReturnedAt',
    label('courierReturnedAt'),
  ),
  expiredCollectedAt: new DateTimeField(
    'expiredCollectedAt',
    label('expiredCollectedAt'),
  ),
  expiredCollectedBy: new RelationToOneField(
    'expiredCollectedBy',
    label('expiredCollectedBy'),
  ),
  customerTakenAt: new DateTimeField(
    'customerTakenAt',
    label('customerTakenAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  inboundAtRange: new DateTimeRangeField(
    'inboundAtRange',
    label('inboundAtRange'),
  ),
  storedAtRange: new DateTimeRangeField(
    'storedAtRange',
    label('storedAtRange'),
  ),
  collectedAtRange: new DateTimeRangeField(
    'collectedAtRange',
    label('collectedAtRange'),
  ),
  overdueDate: new DateTimeField(
    'overdueDate',
    label('overdueDate'),
  ),
  overdueDateRange: new DateTimeRangeField(
    'overdueDateRange',
    label('overdueDate'),
  ),
  status: new EnumeratorField('status', label('status'), [
    {
      id: 'CREATED',
      label: enumeratorLabel('status', 'CREATED'),
    },
    {
      id: 'INBOUND',
      label: enumeratorLabel('status', 'INBOUND'),
    },
    {
      id: 'IN_TRANSIT_TO_STORE',
      label: enumeratorLabel('status', 'IN_TRANSIT_TO_STORE'),
    },
    {
      id: 'CUSTOMER_TAKEN',
      label: enumeratorLabel('status', 'CUSTOMER_TAKEN'),
    },
    {
      id: 'COURIER_TAKEN',
      label: enumeratorLabel('status', 'COURIER_TAKEN'),
    },
    {
      id: 'COURIER_INBOUND',
      label: enumeratorLabel('status', 'COURIER_INBOUND'),
    },
    {
      id: 'COURIER_RETURNED',
      label: enumeratorLabel('status', 'COURIER_RETURNED'),
    },
    {
      id: 'EXPIRED',
      label: enumeratorLabel('status', 'EXPIRED'),
    },
    {
      id: 'EXPIRED_TAKEN',
      label: enumeratorLabel('status', 'EXPIRED_TAKEN'),
    },
    {
      id: 'EXPIRED_INBOUND',
      label: enumeratorLabel('status', 'EXPIRED_INBOUND'),
    },
    {
      id: 'EXPIRED_RETURNED',
      label: enumeratorLabel('status', 'EXPIRED_RETURNED'),
    },
    {
      id: 'STORED',
      label: enumeratorLabel('status', 'STORED'),
    },
    {
      id: 'CUSTOMER_TAKEN_AND_WAITING_FOR_RELEASE',
      label: enumeratorLabel('status', 'CUSTOMER_TAKEN_AND_WAITING_FOR_RELEASE'),
    },
    {
      id: 'CANCELED',
      label: enumeratorLabel('status', 'CANCELED'),
    },
    {
      id: 'FAILED_DELIVERY',
      label: enumeratorLabel('status', 'FAILED_DELIVERY'),
    },
  ], 'multiple'),
  type: new RelationToOneField('type', label('type'), {}),
  customerName: new StringField(
    'customerName',
    label('customerName'),
    {
      min: 6,
      max: 50,
    },
  ),
  customerPhone: new StringField(
    'customerPhone',
    label('customerPhone'),
    {
      min: 13,
      max: 13,
    },
  ),
  notes: new StringField('notes', label('notes'), {
    required: false,
  }),
  customerEmail: new StringField(
    'customerEmail',
    label('customerEmail'),
    {
      min: 6,
      max: 50,
    },
  ),
  eboxCode: new StringField(
    'eboxCode',
    label('eboxCode'),
    {
      min: 4,
      max: 20,
    },
  ),
  compartimentSize: new StringField(
    'compartimentSize',
    label('compartimentSize'),
    {
      min: 1,
      max: 2,
    },
  ),
  notBeforeFirst: new DateTimeField(
    'notBeforeFirst',
    label('notBeforeFirst'),
  ),
  bookOrder: new BooleanField(
    'bookOrder',
    label('bookOrder'),
  ),
  state: new EnumeratorField('state', label('state'), [{
    id: 'RJ',
    label: 'RJ',
  }, {
    id: 'SP',
    label: 'SP',
  }, {
    id: 'MG',
    label: 'MG',
  }]),
};

export default {
  fields,
};
